import { useAuthenticator } from "@aws-amplify/ui-react";
import { getQuicksightDashboardEmbedUrl } from "api/Workspace";
import { useEffect, useState } from "react";

const Dashboard = (props) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [url, setUrl] = useState("");
  const demoUrl =
    "https://ap-northeast-1.quicksight.aws.amazon.com/sn/embed/share/accounts/333392895514/dashboards/75870b39-7113-4c50-86e1-0d9a8c47c857?directory_alias=test-embedding";

  useEffect(() => {
    if (user?.attributes?.sub) {
      getQuicksightDashboardEmbedUrl(user.attributes.email).then((res) => {
        console.log("getQuicksightDashboardEmbedUrl", res);
        const newUrl = res || demoUrl;
        setUrl(newUrl);
      });
    }
  }, [user]);

  return (
    <div style={{ padding: "50px", width: "100%", height: "100%" }}>
      {url && (
        <iframe
          title="Dashboard"
          width="100%"
          height="100%"
          src={url}
          //
        ></iframe>
      )}
    </div>
  );
};

export default Dashboard;
