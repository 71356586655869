import styled from "styled-components";

const PageController = (props) => {
  const {
    currentPage,
    startingPages,
    previousPages,
    nextPages,
    endingPages,
    setCurrentPage,
    hasNext,
    hasPrevious,
  } = props;

  console.log("PageController", props);

  const pages = Array.from(
    new Set(
      [
        ...startingPages,
        ...previousPages,
        currentPage,
        ...nextPages,
        ...endingPages,
      ].sort((a, b) => a - b)
    )
  );
  const theLastPage = pages[pages.length - 1];

  return (
    <PageButtons>
      <button
        onClick={() => setCurrentPage(startingPages[0])}
        disabled={!hasPrevious}
      >
        {"<<"}
      </button>
      <button
        onClick={() =>
          currentPage > 1
            ? setCurrentPage(currentPage - 1)
            : setCurrentPage(currentPage)
        }
        disabled={!hasPrevious}
      >
        {"<"}
      </button>

      {pages.map((page, i) => (
        <>
          {i > 0 && page - pages[i - 1] > 1 ? (
            <span key={`spacer${i}`}>…</span>
          ) : (
            <></>
          )}
          <button
            className="page"
            key={page}
            onClick={() => setCurrentPage(page)}
            disabled={currentPage === page}
          >
            {page}
          </button>
        </>
      ))}

      <button
        onClick={() =>
          currentPage < pages[pages.length - 1]
            ? setCurrentPage(currentPage + 1)
            : setCurrentPage(currentPage)
        }
        disabled={!hasNext}
      >
        {">"}
      </button>
      <button onClick={() => setCurrentPage(theLastPage)} disabled={!hasNext}>
        {">>"}
      </button>
    </PageButtons>
  );
};

const PageButtons = styled.div`
  & button {
    margin: 10px;
    cursor: pointer;
  }

  & button {
    background: none;
    border: none;
    color: #192e55;
    outline: none;
  }

  & button.page:disabled {
    background-color: #2397ce;
    border: 1px solid #2397ce;
    color: white;
    font-weight: bold;
  }

  & button:disabled {
    color: lightgray;
    cursor: initial;
  }
`;

export default PageController;
