import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  getAccessibleReadableWorkspaces,
  getAccessibleWritableWorkspaces,
} from "api/Workspace";
import { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import FreezedFilterSwitch from "components/FreezedFilterSwitch";
import WSDeletionDialog from "components/WSDeleter";
import { getCookie } from "utils/CookieUtil";
import {
  PenEditIcon,
  ReportIcon,
  StackCloneIcon,
  TrashIcon,
  WSFileIcon,
} from "utils/SVGIcons";

import PageController from "components/PageController";

import {
  NotosansjpMediumCloudBurst12px,
  NotosansjpMediumWhite12px,
  NotosansjpNormalNavy10px,
  NotosansjpNormalNavy12px,
} from "../../styledMixins";

const Table = styled.table`
  margin-top: 6px;
  width: calc(100% - 10px);
  & th,
  & td {
    font-size: 12px;
    text-align: left;
  }
  & th {
    white-space: nowrap;
    padding: 0 8px 6px;
  }
  & td {
    height: 45px;
  }
  & td > div {
    /* border-top: 1px solid #92e0ff; */
    /* border-bottom: 0.5px solid rgba(247, 249, 249, 1); */
    border-bottom: 0.5px solid rgba(237, 239, 239, 1);
    margin-top: 10px;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    padding: 0 8px;
  }
  & th.left {
    padding-left: 10px;
  }
  & th.right {
    padding-right: 10px;
  }
  & td.left > div {
    /* border-left: 1px solid #92e0ff; */
    padding-left: 10px;
  }
  & td.right > div {
    /* border-right: 1px solid #92e0ff; */
    padding-right: 10px;
  }
  & td.no-border > div {
    border: none;
  }
  & td.number > div {
    justify-content: center;
  }
`;

const IconDiv = (props) => {
  const { url, style, onClick } = props;
  return (
    <IconFrame
      style={{
        backgroundImage: `url(${url})`,
        ...style,
      }}
      onClick={onClick}
    ></IconFrame>
  );
};

const IconFrame = styled.div`
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;

  /* &:hover {
    box-shadow: 2px 2px 10px rgba(147, 163, 169, 0.3);
  }
  &:active {
    box-shadow: unset;
    color: #192e55;
  } */
`;

const WSList = (props) => {
  const { workspaces, reload } = props;
  const { user } = useAuthenticator((context) => [context.user]);
  const userName = user?.username || "";

  const [workspaceToDelete, setWorkspaceToDelete] = useState(null);

  const navigate = useNavigate();
  const generateGotoWSEdit = (workspace) => (e) => {
    e.preventDefault();
    if (!workspace.permission.write || workspace.freezed) {
      return;
    }
    const midPath = workspace.prepared ? "portfolio" : "properties";
    const path = `/workspace/${workspace.id}/${midPath}/edit`;
    navigate(path);
  };
  const generateGotoWSView = (workspace) => (e) => {
    e.preventDefault();
    if (!workspace.permission.read || !workspace.prepared) {
      return;
    }
    const path = `/workspace/${workspace.id}/portfolio`;
    navigate(path);
  };
  const generateGotoWSReport = (workspace) => (e) => {
    e.preventDefault();
    if (workspace.permission.write && !workspace.freezed) {
      navigate(`/workspace/${workspace.id}/report/edit`);
      return;
    }
    if (!workspace.permission.read || !workspace.prepared) {
      return;
    }
    const path = `/workspace/${workspace.id}/report`;
    navigate(path);
  };
  const generateGotoWSDuplicate = (workspace) => (e) => {
    e.preventDefault();
    const path = `/workspace/${workspace.id}/properties/edit`;
    navigate(path);
  };
  const generateDeleteWS = (workspace) => (e) => {
    e.preventDefault();
    console.log("workspace", workspace);
    setWorkspaceToDelete(workspace);
  };

  const isPropertyFixedWS = (ws) => {
    return !!(ws.ws && ws.customer && ws.am);
  };

  return (
    <>
      <WSDeletionDialog
        workspace={workspaceToDelete}
        onCancel={() => setWorkspaceToDelete(null)}
        processAfterDeletion={reload}
        dismissButtonLabel={null}
      />
      <Table>
        <thead>
          <tr style={{ borderBottom: "1px solid rgb(221, 220, 219)" }}>
            <th>WS名称</th>
            <th>編集</th>
            <th>閲覧</th>
            <th>複製</th>
            <th>削除</th>
            <th>顧客名称</th>
            <th>政策AM名称</th>
            <th>担当部門</th>
            <th>担当者</th>
            <th>作成日</th>
            <th>更新日</th>
            <th>レポート提出日</th>
            <th>レポート閲覧</th>
          </tr>
        </thead>
        <tbody>
          {workspaces.map((workspace, i) => (
            <tr key={`customer-${workspace.id}`}>
              {/* WS名称 */}
              <td style={{ verticalAlign: "middle" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 11,
                    color: workspace.ws ? "inherit" : "rgba(190,190,190,1)",
                  }}
                >
                  <IconDiv
                    url={`/img/${
                      workspace.freezeDate ? "checked_" : ""
                    }folder_icon.svg`}
                  />
                  <TwoRows text={workspace?.ws} defaultText="WS名称未設定" />
                </div>
              </td>
              {/* 編集 */}
              <td style={{ verticalAlign: "middle" }}>
                <div>
                  {workspace.permission.write && !workspace.freezed ? (
                    <PenEditIcon
                      onClick={generateGotoWSEdit(workspace)}
                      style={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </td>
              {/* 閲覧 */}
              <td style={{ verticalAlign: "middle" }}>
                <div>
                  {workspace.permission.read && workspace.prepared && (
                    <WSFileIcon
                      onClick={generateGotoWSView(workspace)}
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                </div>
              </td>
              {/* 複製 */}
              <td style={{ verticalAlign: "middle" }}>
                <div>
                  <StackCloneIcon
                    onClick={generateGotoWSDuplicate(workspace)}
                    style={{ width: 24, height: 24 }}
                  />
                </div>
              </td>
              {/* 削除 */}
              <td style={{ verticalAlign: "middle" }}>
                <div>
                  {workspace.permission.write && !workspace.freezed && (
                    <TrashIcon
                      onClick={generateDeleteWS(workspace)}
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                </div>
              </td>
              {/* 顧客名称 */}
              <td
                className="left"
                // style={i === 0 ? { paddingTop: 10 } : {}}
              >
                <div
                  style={{
                    color: workspace.customer
                      ? "inherit"
                      : "rgba(190,190,190,1)",
                  }}
                >
                  {workspace.customer || "顧客未設定"}
                </div>
              </td>
              {/* 政策AM名称 */}
              <td>
                <div
                  style={{
                    display: "flex",
                    color: workspace.am ? "inherit" : "rgba(190,190,190,1)",
                  }}
                >
                  <div>&nbsp;</div>
                  <TwoRows text={workspace.am} defaultText="政策AM未設定" />
                </div>
              </td>
              {/* 担当部門 */}
              <td>
                <div>{workspace?.dept?.division}</div>
              </td>
              {/* 担当者 */}
              <td>
                <div>{workspace.owner}</div>
              </td>
              {/* 作成日 */}
              <td>
                <div>{workspace.createDate}</div>
              </td>
              {/* 更新日 */}
              <td>
                <div>{workspace.updateDate}</div>
              </td>
              {/* レポート提出日 */}
              <td>
                <div
                  style={{
                    color: workspace.freezeDate
                      ? "inherit"
                      : "rgba(190,190,190,1)",
                  }}
                >
                  {workspace.freezeDate ||
                    (isPropertyFixedWS(workspace) ? "未提出" : "未作成")}
                </div>
              </td>
              {/* レポート閲覧 */}
              <td className="right" style={{ verticalAlign: "middle" }}>
                <div>
                  {isPropertyFixedWS(workspace) ? (
                    <ReportIcon
                      style={{ cursor: "pointer" }}
                      onClick={generateGotoWSReport(workspace)}
                      color={workspace.freezeDate ? "#2397CE" : "#1FD7FF"}
                      checked={!!workspace.freezeDate}
                    />
                  ) : (
                    // <IconDiv
                    //   url={`/img/report_${
                    //     workspace.freezeDate ? "fixed" : "editing"
                    //   }.svg`}
                    //   style={{ cursor: "pointer" }}
                    //   onClick={generateGotoWSReport(workspace)}
                    // />
                    <div></div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const SearchPanel = (props) => {
  const {
    keyword,
    changeKeywordHandler,
    checkBoxes,
    sortBy,
    generateRadioButtonChangeHandler,
  } = props;
  return (
    <SearchForm>
      <SearchFrame>
        <SearchField
          placeholder="Search keywords here"
          value={keyword}
          onChange={changeKeywordHandler}
        />
        <SearchButton />
      </SearchFrame>
      <SearchOptionButtons>
        {checkBoxes.map((checkBox, i) => (
          <div key={checkBox.value}>
            <RadioButton
              name="sort-order"
              id={checkBox.value}
              value={checkBox.value}
              type="radio"
              checked={sortBy === checkBox.value}
              onChange={generateRadioButtonChangeHandler(checkBox.value)}
            />
            <RadioButtonLabel
              htmlFor={checkBox.value}
              key={checkBox.value}
              className={checkBox.value === sortBy ? "checked" : ""}
              onClick={generateRadioButtonChangeHandler(checkBox.value)}
            >
              {checkBox.label}
            </RadioButtonLabel>
          </div>
        ))}
      </SearchOptionButtons>
    </SearchForm>
  );
};

const SearchForm = styled.div`
  /* width: 100%; */
  flex-grow: 1;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding-bottom: 15px;
`;

const SearchFrame = styled.div`
  margin-top: 2px;
  /* width: 400px; */
  flex-grow: 1;
  height: 30px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  background-color: #e5eced;
`;
const SearchField = styled.input`
  ${NotosansjpNormalNavy12px}
  /* width: 248px; */
  flex-grow: 1;
  height: 18px;
  margin-left: 12px;
  border: none;
  outline: unset;
  background-color: transparent;
  &::placeholder {
    color: #9fa0a0;
  }
`;
const SearchButton = styled.button`
  width: 20px;
  height: 20px;
  background-image: url(/img/magnifier.svg);
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  margin-right: 9px;
  border: none;
`;

const SearchOptionButtons = styled.div`
  margin-top: 7px;
  /* width: 300px; */
  height: 20px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 5px;
`;

const RadioButton = styled.input`
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
`;

const RadioButtonLabel = styled.label`
  ${NotosansjpNormalNavy10px}
  display: flex;
  align-items: center;
  color: #192e55;
  border-radius: 10px;
  border: 1px solid #dddcdb;
  height: 20px;
  text-align: center;
  padding: 3px 12px;
  white-space: nowrap;
  cursor: pointer;

  &.checked {
    background: #d2f2ff;
    border: 1px solid #92e0ff;
  }
`;

const Frame = styled.div`
  padding-top: 29px;

  & .graph-tab .react-tabs__tab-panel {
    background-color: #ffffff;
    height: calc(100vh - 141px);
    overflow-y: scroll;
    padding: 0 50px;
  }

  & .react-tabs__tab-panel {
    display: none;
  }

  & .react-tabs__tab-panel--selected {
    display: block;
  }

  & .graph-tab .react-tabs__tab {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 45px;
    border: unset;
    align-items: center;
    justify-content: center;
    border-radius: unset;
    ${NotosansjpMediumCloudBurst12px}
    color: #2397CE;
    position: relative;
    bottom: -1px;
    cursor: pointer;
  }
  & .react-tabs__tab--selected {
    color: #192e55;
    background-color: #ffffff;
  }

  & .react-tabs__tab--selected .bar {
    width: 120px;
    height: 10px;
    display: block;
    position: absolute;
    top: 42px;
    z-index: 9999999;
  }
  & .react-tabs__tab bar {
    /* margin-top: 10px; */
  }

  & .react-tabs__tab-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin: 0;
    border-bottom: 10px solid #ffffff;
  }
`;

const TwoRows = (props) => {
  const { text, defaultText } = props;
  const value = text
    ? text.split("\n").map((l, i) => <div key={i}>{l}</div>)
    : defaultText;
  return (
    <TwoRowsDiv>
      <div> {value}</div>
    </TwoRowsDiv>
  );
};

const TwoRowsDiv = styled.div`
  padding: 0;
  display: flex;
  gap: 20px;
`;

const Workspace = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [userWSList, setUserWSList] = useState([]);

  const [shouldReload, setShouldReload] = useState(true);
  const [departmentWSList, setDepartmentWSList] = useState([]);
  const [filteredUserWSList, setFilteredUserWSList] = useState([]);
  const [filteredDepartmentWSList, setFilteredDepartmentWSList] = useState([]);
  const [keyword, setKeyword] = useState(getCookie("WSListSearchKeyword", ""));
  const [sortBy, setSortBy] = useState(
    getCookie("WSListSortBy", "alphabetical-ascend")
  );
  useEffect(() => {
    document.cookie = `WSListSortBy=${sortBy}`;
  }, [sortBy]);

  const [freezedFilterMode, setFreezedFilterMode] = useState(false);
  const onFreezedFilterModeChange = (e) => {
    e.preventDefault();
    setFreezedFilterMode(!freezedFilterMode);
  };

  const { user } = useAuthenticator((context) => [context.user]);
  // const userName = user?.username || "";
  // const userId = user?.attributes?.sub;

  const [limit, setLimit] = useState(10);
  const [departmentWSCount, setDepartmentWSCount] = useState(0);
  const [userWSCount, setUserWSCount] = useState(0);

  const numStartingPages = 3;
  const numEndingPages = 3;
  const numPreviousPages = 2;
  const numNextPages = 2;

  const [startingUserPages, setStartingUserPages] = useState([]);
  const [endingUserPages, setEndingUserPages] = useState([]);
  const [previousUserPages, setPreviousUserPages] = useState([]);
  const [nextUserPages, setNextUserPages] = useState([]);

  const [startingDepartmentPages, setStartingDepartmentPages] = useState([]);
  const [endingDepartmentPages, setEndingDepartmentPages] = useState([]);
  const [previousDepartmentPages, setPreviousDepartmentPages] = useState([]);
  const [nextDepartmentPages, setNextDepartmentPages] = useState([]);

  const [currentDepartmentPage, setCurrentDepartmentPage] = useState(1);
  const [currentUserPage, setCurrentUserPage] = useState(1);

  const [hasNextUser, setHasNextUser] = useState(false);
  const [hasPreviousUser, setHasPreviousUser] = useState(false);

  const [hasNextDepartment, setHasNextDepartment] = useState(false);
  const [hasPreviousDepartment, setHasPreviousDepartment] = useState(false);

  const arrangePages = (currentPage, wsCount, pageType) => {
    // const wsCount = pageType === "department" ? departmentWSCount : userWSCount;
    const numPages = Math.ceil(wsCount / limit);
    console.log("numPages", numPages);
    const newStartingPages = [];
    const newEndingPages = [];
    const newPreviousPages = [];
    const newNextPages = [];
    for (let i = 1; i <= numPages; i++) {
      if (i <= numStartingPages) {
        newStartingPages.push(i);
      } else if (i > numPages - numEndingPages) {
        newEndingPages.push(i);
      } else if (currentPage - numPreviousPages <= i && i < currentPage) {
        newPreviousPages.push(i);
      } else if (currentPage < i && i <= currentPage + numNextPages) {
        newNextPages.push(i);
      }
    }
    const hasNext = currentPage < numPages;
    const hasPrevious = currentPage > 1;

    console.log("pageType", pageType);
    console.log("newStartingPages", newStartingPages);
    console.log("newPreviousPages", newPreviousPages);
    console.log("currentPage", currentPage);
    console.log("newNextPages", newNextPages);
    console.log("newEndingPages", newEndingPages);
    if (pageType === "department") {
      setDepartmentWSCount(wsCount);
      // setCurrentDepartmentPage(currentPage);
      setStartingDepartmentPages(newStartingPages);
      setEndingDepartmentPages(newEndingPages);
      setPreviousDepartmentPages(newPreviousPages);
      setNextDepartmentPages(newNextPages);

      setHasNextDepartment(hasNext);
      setHasPreviousDepartment(hasPrevious);
    } else {
      setUserWSCount(wsCount);
      // setCurrentUserPage(currentPage);
      setStartingUserPages(newStartingPages);
      setEndingUserPages(newEndingPages);
      setPreviousUserPages(newPreviousPages);
      setNextUserPages(newNextPages);

      setHasNextUser(hasNext);
      setHasPreviousUser(hasPrevious);
    }
  };

  useEffect(() => {
    if (!shouldReload) return;
    const fetchWSList = async () => {
      // via API call
      // const {
      //   readableWSList: newDepartmentWSList,
      //   writableWSList: newUserWSList,
      // } = await getAccessibleWorkspaces();
      const {
        workspaces: newDepartmentWSList,
        totalCount: newDepartmentWSListCount,
      } = await getAccessibleReadableWorkspaces({ limit, offset: 0 });
      console.log("newDepartmentWSListCount", newDepartmentWSListCount);
      // setDepartmentWSCount(newDepartmentWSListCount);
      arrangePages(1, newDepartmentWSListCount || 0, "department");

      const { workspaces: newUserWSList, totalCount: newUserWSListCount } =
        await getAccessibleWritableWorkspaces({ limit, offset: 0 });

      console.log("newUserWSListCount", newUserWSListCount);
      arrangePages(1, newUserWSListCount || 0, "user");

      // existing code
      //  const newUserWSList = await getEditableWSList(userName);

      const newUserWSListMod = newUserWSList.map((ws) => ({
        ...ws,
        prepared: ws?.ws !== null && ws?.am !== null && ws?.customer !== null,
      }));

      setUserWSList(newUserWSListMod);
      setFilteredUserWSList(newUserWSListMod);

      // const newDepartmentWSList = await getViewableWSList(userName);
      const newDepartmentWSListMod = newDepartmentWSList.map((ws) => ({
        ...ws,
        prepared: ws?.ws !== null && ws?.am !== null && ws?.customer !== null,
      }));

      setDepartmentWSList(newDepartmentWSListMod);
      setFilteredDepartmentWSList(newDepartmentWSListMod);
      setShouldReload(false);
    };
    fetchWSList();
  }, [shouldReload]);

  useEffect(() => {
    const fetchWSList = async () => {
      const newOffset = (currentUserPage - 1) * limit;
      const newSortBy = getSortBy();
      const newSortDirection = getSortDirection();
      const newOnlyFrozen = freezedFilterMode;

      const params = {
        limit,
        offset: newOffset,
        keyword,
        sortBy: newSortBy,
        sortDirection: newSortDirection,
        onlyFrozen: newOnlyFrozen,
      };

      const { workspaces: newUserWSList, totalCount: newUserWSListCount } =
        await getAccessibleWritableWorkspaces(params);
      if (newUserWSListCount > 0) {
        const newUserWSListMod = newUserWSList.map((ws) => ({
          ...ws,
          prepared: ws?.ws !== null && ws?.am !== null && ws?.customer !== null,
        }));
        setUserWSList(newUserWSListMod);
        setFilteredUserWSList(newUserWSListMod);
      }
      arrangePages(currentUserPage, newUserWSListCount || 0, "user");
    };
    fetchWSList();
  }, [currentUserPage]);

  useEffect(() => {
    const fetchWSList = async () => {
      const newOffset = (currentDepartmentPage - 1) * limit;
      const newSortBy = getSortBy();
      const newSortDirection = getSortDirection();
      const newOnlyFrozen = freezedFilterMode;
      const params = {
        limit,
        offset: newOffset,
        keyword,
        sortBy: newSortBy,
        sortDirection: newSortDirection,
        onlyFrozen: newOnlyFrozen,
      };

      const {
        workspaces: newDepartmentWSList,
        totalCount: newDepartmentWSListCount,
      } = await getAccessibleReadableWorkspaces(params);
      if (newDepartmentWSListCount > 0) {
        const newDepartmentWSListMod = newDepartmentWSList.map((ws) => ({
          ...ws,
          prepared: ws?.ws !== null && ws?.am !== null && ws?.customer !== null,
        }));
        setDepartmentWSList(newDepartmentWSListMod);
        setFilteredDepartmentWSList(newDepartmentWSListMod);
      }
      arrangePages(
        currentDepartmentPage,
        newDepartmentWSListCount || 0,
        "department"
      );
    };
    fetchWSList();
  }, [currentDepartmentPage]);

  const getSortBy = () => {
    switch (sortBy) {
      case "alphabetical-ascend":
        return "WORKSPACE_NAME";
      case "alphabetical-descend":
        return "WORKSPACE_NAME";
      case "customer-alphabetical-ascend":
        return "CLIENT_NAME";
      case "customer-alphabetical-descend":
        return "CLIENT_NAME";
      case "update-date-ascend":
        return "UPDATED_AT";
      default: // case "update-date-descend":
        return "UPDATED_AT";
    }
  };

  const getSortDirection = () => {
    switch (sortBy) {
      case "alphabetical-ascend":
        return "ASC";
      case "alphabetical-descend":
        return "DESC";
      case "customer-alphabetical-ascend":
        return "ASC";
      case "customer-alphabetical-descend":
        return "DESC";
      case "update-date-ascend":
        return "ASC";
      default: // case "update-date-descend":
        return "DESC";
    }
  };

  useEffect(() => {
    // TODO: freezedFilterMode によるフィルタリングをAPIに実装し、ここでそれを使ったAPI callをする
    console.log("useEffect for keyword, freezedFilterMode, sortBy");
    console.log("freezedFilterMode", freezedFilterMode);
    const fetchWSList = async () => {
      setCurrentDepartmentPage(1);
      setCurrentUserPage(1);
      const newOffset = 0;
      const newSortBy = getSortBy();
      const newSortDirection = getSortDirection();
      const newOnlyFrozen = freezedFilterMode;
      const params = {
        limit,
        offset: newOffset,
        keyword,
        sortBy: newSortBy,
        sortDirection: newSortDirection,
        onlyFrozen: newOnlyFrozen,
      };
      const { workspaces: newUserWSList, totalCount: newUserWSListCount } =
        await getAccessibleWritableWorkspaces(params);
      if (newUserWSListCount > 0) {
        const newUserWSListMod = newUserWSList.map((ws) => ({
          ...ws,
          prepared: ws?.ws !== null && ws?.am !== null && ws?.customer !== null,
        }));
        setUserWSList(newUserWSListMod);
        setFilteredUserWSList(newUserWSListMod);
        setUserWSCount(newUserWSListCount);

        console.log("newUserWSListCount", newUserWSListCount);
      } else {
        setUserWSList([]);
        setFilteredUserWSList([]);
        setUserWSCount(0);
      }
      arrangePages(1, newUserWSListCount || 0, "user");

      const {
        workspaces: newDepartmentWSList,
        totalCount: newDepartmentWSListCount,
      } = await getAccessibleReadableWorkspaces(params);
      if (newDepartmentWSListCount > 0) {
        const newDepartmentWSListMod = newDepartmentWSList.map((ws) => ({
          ...ws,
          prepared: ws?.ws !== null && ws?.am !== null && ws?.customer !== null,
        }));
        setDepartmentWSList(newDepartmentWSListMod);
        setFilteredDepartmentWSList(newDepartmentWSListMod);
        setDepartmentWSCount(newDepartmentWSListCount);

        console.log("newDepartmentWSListCount", newDepartmentWSListCount);
      } else {
        setDepartmentWSList([]);
        setFilteredDepartmentWSList([]);
        setDepartmentWSCount(0);
      }
      arrangePages(1, newDepartmentWSListCount || 0, "department");
    };
    fetchWSList();
  }, [keyword, freezedFilterMode, sortBy, limit]);

  const reloadWSList = () => {
    setShouldReload(true);
  };

  useEffect(() => {}, [freezedFilterMode]);

  const checkBoxes = [
    { label: "WS名称昇順", value: "alphabetical-ascend" },
    { label: "WS名称降順", value: "alphabetical-descend" },
    { label: "顧客名称昇順", value: "customer-alphabetical-ascend" },
    { label: "顧客名称降順", value: "customer-alphabetical-descend" },
    {
      label: "更新日昇順",
      value: "update-date-ascend",
    },
    {
      label: "更新日降順",
      value: "update-date-descend",
    },
  ];

  const sort = (list) => {
    switch (sortBy) {
      case "alphabetical-ascend":
        return list.sort((a, b) => (a?.ws || "").localeCompare(b.ws));
      case "alphabetical-descend":
        return list.sort((a, b) => (b?.ws || "").localeCompare(a.ws));
      case "customer-alphabetical-ascend":
        return list.sort((a, b) => {
          const customerLevel = (a?.customer || "").localeCompare(b?.customer);
          if (customerLevel !== 0) {
            return customerLevel;
          } else {
            return (a?.name || "").localeCompare(b.name);
          }
        });
      case "customer-alphabetical-descend":
        return list.sort((a, b) => {
          const customerLevel = (b?.customer || "").localeCompare(a.customer);
          if (customerLevel !== 0) {
            return customerLevel;
          } else {
            return (b?.name || "").localeCompare(a.name);
          }
        });
      case "update-date-ascend":
        return list.sort((a, b) => a.updateDate.localeCompare(b.updateDate));
      case "update-date-descend":
        return list.sort((a, b) => b.updateDate.localeCompare(a.updateDate));
      default:
        return list;
    }
  };

  const changeKeywordHandler = (e) => {
    e.preventDefault();
    const newKeyword = e.target.value;
    setKeyword(newKeyword);
    document.cookie = `WSListSearchKeyword=${newKeyword}`;
  };

  const generateRadioButtonChangeHandler = (value) => {
    return (e) => {
      e.preventDefault();
      setSortBy(value);
    };
  };

  const navigate = useNavigate();

  const gotoCreateNewWS = (e) => {
    e.preventDefault();
    navigate("/workspace/new/properties/edit");
  };

  return (
    <Frame>
      <div
        style={{
          fontSize: "14px",
          color: "#2397CE",
          marginLeft: 50,
          height: 106,
        }}
      >
        WS一覧
      </div>
      <Tabs
        className="graph-tab"
        selectedIndex={tabIndex}
        onSelect={(index) => {
          setTabIndex(index);
        }}
      >
        <TabList style={{ marginLeft: 50 }}>
          <SearchPanel
            keyword={keyword}
            changeKeywordHandler={changeKeywordHandler}
            checkBoxes={checkBoxes}
            sortBy={sortBy}
            generateRadioButtonChangeHandler={generateRadioButtonChangeHandler}
          />

          <div style={{ display: "flex", flexDirection: "row" }}>
            <Tab>
              担当顧客<div className="bar"></div>
            </Tab>
            <Tab>
              部署全体<div className="bar"></div>
            </Tab>
          </div>
        </TabList>

        <TabPanel>
          <FreezedFilterSwitch
            freezedFilterMode={freezedFilterMode}
            onFreezedFilterModeChange={onFreezedFilterModeChange}
          />

          <WSList workspaces={filteredUserWSList} reload={reloadWSList} />
          <div className="spacer" style={{ height: "50px" }} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: 12 }}>表示件数 </span>
            <select
              style={{
                marginRight: 20,
                marginLeft: 10,
                fontSize: 12,
                cursor: "pointer",
              }}
              value={limit}
              onChange={(e) => {
                e.preventDefault();
                console.log("select onChange e.target.value", e.target.value);
                console.log("e", e);
                setLimit(e.target.value);
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            <PageController
              currentPage={currentUserPage}
              startingPages={startingUserPages}
              previousPages={previousUserPages}
              nextPages={nextUserPages}
              endingPages={endingUserPages}
              setCurrentPage={setCurrentUserPage}
              hasNext={hasNextUser}
              hasPrevious={hasPreviousUser}
            />
          </div>

          <div className="spacer" style={{ height: "20px" }} />

          <div style={{ display: "flex", flexDirection: "row" }}>
            <OpenButton onClick={gotoCreateNewWS}>
              <img src="/img/create_ws_icon.svg" />
              <div>WS新規作成</div>
            </OpenButton>
          </div>
        </TabPanel>
        <TabPanel>
          <FreezedFilterSwitch
            freezedFilterMode={freezedFilterMode}
            onFreezedFilterModeChange={onFreezedFilterModeChange}
          />
          <WSList workspaces={filteredDepartmentWSList} reload={reloadWSList} />
          <div className="spacer" style={{ height: "20px" }} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: 12 }}>表示件数 </span>
            <select
              style={{
                marginRight: 20,
                marginLeft: 10,
                fontSize: 12,
                cursor: "pointer",
              }}
              value={limit}
              onChange={(e) => {
                e.preventDefault();
                console.log("select onChange e.target.value", e.target.value);
                console.log("e", e);
                setLimit(e.target.value);
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <PageController
              currentPage={currentDepartmentPage}
              startingPages={startingDepartmentPages}
              previousPages={previousDepartmentPages}
              nextPages={nextDepartmentPages}
              endingPages={endingDepartmentPages}
              setCurrentPage={setCurrentDepartmentPage}
              hasNext={hasNextDepartment}
              hasPrevious={hasPreviousDepartment}
            />
          </div>

          <div className="spacer" style={{ height: "20px" }} />

          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 40 }}
          >
            <OpenButton onClick={gotoCreateNewWS}>
              <img src="/img/create_ws_icon.svg" />
              <div>WS新規作成</div>
            </OpenButton>
          </div>
        </TabPanel>
      </Tabs>
    </Frame>
  );
};

const OpenButton = styled.button`
  padding: 8px 15px;
  margin-left: 10px;
  /* width: 140px; */
  height: 30px;
  border-radius: 15px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  ${NotosansjpMediumWhite12px}
  font-size: 11px;
  font-weight: 500;
  background-color: #2397ce;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #2b7eb0;
  }
  &:active {
    background-color: #192e55;
  }
  &.inactive {
    background-color: #9fa0a0;
    cursor: initial;
  }
`;

export default Workspace;
